<template>
  <div class="typing-indicator">
    <div
      v-for="i in 3"
      :key="i"
      class="dot"
      :class="'dot' + i"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'TypingIndicator',
};
</script>

<style scoped>
.typing-indicator {
  display: flex;
  justify-content: space-between;
  width: 64px;
  background-color: #ececec;
  padding: 0.75rem 1rem;
  gap: 3px;
  border-radius: 0.5rem;
}

.dot {
  background: #b0b2b4;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  animation: pulse 1s infinite;
}

.dot1 {
  animation-delay: 0s;
}
.dot2 {
  animation-delay: 0.2s;
}
.dot3 {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
